import { getCurrentInstance, onMounted, ref } from "@vue/composition-api";
import store from "@/store";

// Notification
import { showToast } from "@/libs/utils/showToast";

export default function useProjectsList() {
	const refProjectListTable = ref(null);
	let instance = getCurrentInstance().proxy;

	const searchQuery = ref("");

	const refetchData = () => {
		refProjectListTable.value.refresh();
	};
	onMounted(() => {
		store
			.dispatch("projects/fetchProjects", { pagesize: 10000 })
			.then((response) => {
				instance.data = response.data;
				showToast(null, "获取成功", "您已成功获取项目列表");
			})
			.catch((error) => {
				showToast(error);
			});
	});

	return {
		refetchData,
		searchQuery,
		refProjectListTable,
	};
}
